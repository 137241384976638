import React, { useEffect } from "react";
import styled from "styled-components";

import FetchingClickShield from "js/react/components/FetchingClickShield";
import { Authenticate } from "js/react/views/Auth/Authenticate";
import Api from "js/core/api";

const Container = styled.div`
    height: 100%;
    width: 100%;
    
    background-color: #f8fafc;
`;

function AuthenticatedHandler() {
    useEffect(async () => {
        try {
            const { token } = await Api.customToken.get();
            Office.context.ui.messageParent(JSON.stringify({
                eventType: "close",
                token
            }));
        } catch (err) {
            Office.context.ui.messageParent(JSON.stringify({
                eventType: "close",
                err
            }));
        }

        // Uncomment code below and remove code above to redirect to
        // choose plan page instead of closing the dialog
        //
        // window.location.href = "/ppt-addin/choosePlan";
    }, []);

    return (
        <FetchingClickShield fetching={true} backgroundColor="#f8fafc" />
    );
}

function PPTAddinAuthenticate({ match: { params: { page } } }) {
    return (
        <Container>
            <Authenticate defaultPage={page ?? "signIn"} >
                <AuthenticatedHandler />
            </Authenticate>
        </Container>
    );
}

export default PPTAddinAuthenticate;
